


// import React, { useState } from "react";
// import { Container, Row, Col, Modal, Button } from "react-bootstrap";
// import BlockTitle from "./BlockTitle";
// import 'bootstrap-icons/font/bootstrap-icons.css';
// import qrCodeImage from '../assets/images/Qrnoi.png';

// const Pricing = () => {
//   const [showModal, setShowModal] = useState(false);
//   const [selectedPackage, setSelectedPackage] = useState({});

//   // Thông tin gói phí
//   const packages = [
//     {
//       name: 'GÓI TRẢI NGHIỆM',
//       price: '20.000 VNĐ',
//       features: [
//         'Thời hạn sử dụng: 1 tháng',
//         'Được xem hình ảnh hồ sơ (làm mờ/rõ nét)',
//         'Được mở chức năng tìm kiếm theo 1 tiêu chí: Nơi ở',
//         'Được chủ động chọn "Quan Tâm" hồ sơ người khác: 2 lượt/ngày'
//       ]
//     },
//     {
//       name: 'GÓI TIÊU CHUẨN',
//       price: '200.000 VNĐ',
//       features: [
//         'Thời hạn sử dụng: 6 tháng',
//         'Được xem hình ảnh đối phương (làm mờ/rõ nét)',
//         'Được mở chức năng tìm kiếm theo 3 tiêu chí: Nơi ở, giới tính, tình trạng hôn nhân',
//         'Được chủ động chọn "Quan Tâm" hồ sơ người khác: 2 lượt/ngày',
//         'Không giới hạn số lượt được người khác "Quan Tâm" hồ sơ mình',
//         'Nếu nâng cấp lên gói khác thì đóng phí 100% gói mới đó'
//       ]
//     },
//     {
//       name: 'GÓI NÂNG CAO',
//       price: '600.000 VNĐ',
//       features: [
//         'Thời hạn sử dụng: 6 tháng',
//         'Được xem hình ảnh đối phương (làm mờ/rõ nét)',
//         'Được mở chức năng tìm kiếm theo 6 tiêu chí: Nơi ở, giới tính, tình trạng hôn nhân, năm sinh, tôn giáo, trình độ',
//         'Được chủ động chọn "Quan Tâm" hồ sơ người khác: 6 lượt/ngày',
//         'Không giới hạn số lượt được người khác "Quan Tâm" hồ sơ mình',
//         'Được xuất hiện trong danh mục "Hồ Sơ Nổi Bật" giúp tăng cơ hội và số lượt hiển thị',
//         'Nếu nâng cấp lên gói khác thì đóng phí 100% gói mới đó'
//       ]
//     },
//     {
//       name: 'GÓI VIP',
//       price: '3.000.000 VNĐ',
//       features: [
//       'Thời hạn sử dụng: 6 tháng',
// 'Được xem hình ảnh đối phương (làm mờ/rõ nét)',
// 'Được mở chức năng tìm kiếm theo 9 tiêu chí: Nơi ở, giới tính, tình trạng hôn nhân, năm sinh, tôn giáo, trình độ, chiều cao, quê quán, nghề nghiệp',
// 'Được chủ động chọn "Quan Tâm" hồ sơ người khác: Không giới hạn số lượt',
// 'Không giới hạn số lượt được người khác "Quan Tâm" hồ sơ mình',
// 'Được xuất hiện trong danh mục "Hồ Sơ Nổi Bật" giúp tăng cơ hội và số lượt hiển thị',
// 'Được quyền "Tự Ẩn Hồ Sơ" của mình, nhưng vẫn tự tìm kiếm và xem hồ sơ người khác',
// 'Nếu nâng cấp lên gói khác thì đóng phí chênh lệch so với gói mới đó'
//       ]
//     },
//     {
//       name: 'GÓI SIÊU VIP',
//       price: '6.000.000 VNĐ',
//       features: [
//         'Thời hạn sử dụng: 6 tháng',
//         'Được xem hình ảnh đối phương (làm mờ/rõ nét)',
//         'Nhận đầy đủ các quyền lợi như GÓI VIP',
//        'Nhưng nhận thêm một số quyền lợi ĐẶC BIỆT KHÁC',
//        'Được BQT trực tiếp hỗ trợ kết nối',
//        'Chi tiết vui lòng liên hệ BQT để nhận tư vấn'
        
//       ]
//     }
//   ];

//   // Hiển thị modal với thông tin gói đã chọn
//   const handleSelectPackage = (pkg) => {
//     setSelectedPackage(pkg);
//     setShowModal(true);
//   };

//   return (
//     <section className="pricing-one" id="pricing" style={{ margin: '0 20px' }}>
//       <BlockTitle textAlign="center" paraText="Thông tin " titleText={`Các gói hồ sơ`} />
//       <div id="pricing-plans">
//         <Row className="pricing-row">
//           {packages.map((pkg, index) => (
//             <Col key={index} lg={3} md={6} sm={12} xs={12} className="mb-4">
//               <div className="pricing-one__single">
//                 <div className="pricing-one__inner">
//                   <p>{pkg.name}</p>
//                   <h3>{pkg.price}</h3>
//                   <ul className="list-unstyled pricing-one__list">
//                     {pkg.features.map((feature, i) => (
//                       <li key={i} style={{ textAlign: 'left', position: 'relative', paddingLeft: '30px', lineHeight: '1.6' }}>
//                         <i className="bi bi-check" style={{ fontSize: '1.2em', position: 'absolute', left: '0', top: '50%', transform: 'translateY(-50%)' }}></i> {feature}
//                       </li>
//                     ))}
//                   </ul>
//                   <a 
//                     href="#none" 
//                     className="thm-btn pricing-one__btn" 
//                     style={{ marginTop: 'auto' }} 
//                     onClick={() => handleSelectPackage(pkg)}
//                   >
//                     <span>Chọn gói</span>
//                   </a>
//                 </div>
//               </div>
//             </Col>
//           ))}
//         </Row>
//       </div>

//       {/* Modal thông tin thanh toán */}
//       <Modal 
//         show={showModal} 
//         onHide={() => setShowModal(false)} 
//         centered 
//         dialogClassName="custom-modal"
//       >
//         <Modal.Header closeButton>
//           <Modal.Title>Thông tin chuyển khoản</Modal.Title>
//         </Modal.Header>
//         <Modal.Body>
//           <p style={{ marginBottom: '5px' }}><strong>Gói đã chọn:</strong> {selectedPackage.name}</p>
//           <p style={{ marginBottom: '5px' }}><strong>Giá:</strong> {selectedPackage.price}</p>
//           <hr style={{ width: '50%', margin: '20px auto', border: '1px solid #ccc' }} />
//           <p style={{ marginBottom: '5px' }}><span style={{ fontWeight: "bold" }}>Ngân hàng Quân Đội MB Bank</span></p>
//           <p style={{ marginBottom: '5px' }}>Số TK: <span style={{ fontWeight: "bold" }}>123996699</span></p>
//           <p style={{ marginBottom: '5px' }}>Tên TK: <span style={{ fontWeight: "bold" }}>Nối Group</span></p>
//           <p style={{ marginBottom: '5px' }}>Nội dung ghi: <span style={{ fontWeight: "bold" }}>SĐT + CK IUDI APP</span></p>
//           <p style={{ marginBottom: '5px' }}>Sau đó chụp hình hóa đơn gửi qua Zalo BQT: 0866.428.869</p>
//           <div style={{ textAlign: 'center', margin: '15px 0' }}>
//             <img src={qrCodeImage} alt="QR Code" style={{ width: "200px", height: "200px" }} />
//           </div>
          
//         </Modal.Body>
//         <Modal.Footer>
//           <Button variant="secondary" onClick={() => setShowModal(false)}>Đóng</Button>
//         </Modal.Footer>
//       </Modal>
//     </section>
//   );
// };

// export default Pricing;








import React, { useState } from "react";
import { Container, Row, Col, Modal, Button } from "react-bootstrap";
import BlockTitle from "./BlockTitle";
import 'bootstrap-icons/font/bootstrap-icons.css';
import qrCodeImage from '../assets/images/qrcode.jpg';

const Pricing = () => {
  const [showModal, setShowModal] = useState(false);
  const [selectedPackage, setSelectedPackage] = useState({});

  // Thông tin gói phí
  const packages = [
    {
      name: 'GÓI TRẢI NGHIỆM',
      price: '200.000 VNĐ',
      features: [
        'Thời hạn sử dụng: 1 tháng',
        'Được xem hình ảnh hồ sơ (làm mờ/rõ nét)',
        'Được mở chức năng tìm kiếm theo 2 tiêu chí: Nơi ở, giới tính',
        'Được chủ động chọn "Quan Tâm" hồ sơ người khác: 2 lượt/ngày'
      ]
    },
    {
      name: 'GÓI TIÊU CHUẨN',
      price: '500.000 VNĐ',
      features: [
        'Thời hạn sử dụng: 6 tháng',
        'Được xem hình ảnh hồ sơ (làm mờ/rõ nét)',
        'Được mở chức năng tìm kiếm theo 3 tiêu chí: Nơi ở, giới tính, tình trạng hôn nhân',
        'Được chủ động chọn "Quan Tâm" hồ sơ người khác: 2 lượt/ngày',
      ]
    },
    {
      name: 'GÓI NÂNG CAO',
      price: '900.000 VNĐ',
      features: [
        'Thời hạn sử dụng: 6 tháng',
        'Được xem hình ảnh hồ sơ (làm mờ/rõ nét)',
        'Được mở chức năng tìm kiếm theo 6 tiêu chí: Nơi ở, giới tính, tình trạng hôn nhân, năm sinh, tôn giáo, trình độ',
        'Được chủ động chọn "Quan Tâm" hồ sơ người khác: 6 lượt/ngày',
        'Được xuất hiện trong danh mục "Hồ Sơ Nổi Bật" giúp tăng cơ hội và số lượt hiển thị',
      ]
    },
    {
      name: 'GÓI VIP',
      price: '3.000.000 VNĐ',
      features: [
      'Thời hạn sử dụng: 6 tháng',
'Được xem hình ảnh hồ sơ (làm mờ/rõ nét)',
'Được mở chức năng tìm kiếm theo 9 tiêu chí: Nơi ở, giới tính, tình trạng hôn nhân, năm sinh, tôn giáo, trình độ, chiều cao, quê quán, nghề nghiệp',
'Được chủ động chọn "Quan Tâm" hồ sơ người khác: Không giới hạn số lượt',
'Được xuất hiện trong danh mục "Hồ Sơ Nổi Bật" giúp tăng cơ hội và số lượt hiển thị',
'Được quyền "Tự Ẩn Hồ Sơ" của mình, nhưng vẫn tự tìm kiếm và xem hồ sơ người khác',
      ]
    },
    {
      name: 'GÓI SIÊU VIP',
      price: '6.000.000 VNĐ',
      features: [
        'Thời hạn sử dụng: 6 tháng',
        'Nhận đầy đủ các quyền lợi như GÓI VIP',
       'Nhưng nhận thêm một số quyền lợi ĐẶC BIỆT KHÁC',
       'Được BQT trực tiếp hỗ trợ kết nối',
       'Chi tiết vui lòng liên hệ BQT để nhận tư vấn'
        
      ]
    }
  ];

  // Hiển thị modal với thông tin gói đã chọn
  const handleSelectPackage = (pkg) => {
    setSelectedPackage(pkg);
    setShowModal(true);
  };
// Determine if the last row has fewer than four items for centering
const isLastRowCentered = packages.length % 4 !== 0;
  return (
    <section className="pricing-one" id="pricing" style={{ margin: '0 20px' }}>
      <BlockTitle textAlign="center" paraText="Thông tin " titleText={`Các gói hồ sơ`} />
      <div id="pricing-plans">
        <Row className={`pricing-row ${packages.length % 4 !== 0 ? 'justify-content-center' : ''}`}>
          {packages.map((pkg, index) => (
            <Col key={index} lg={3} md={6} sm={12} xs={12} className="mb-4">
              <div className={`pricing-one__single ${index === packages.length - 1 ? 'super-vip' : ''}`}>
                <div className="pricing-one__inner">
                  <p>{pkg.name}</p>
                  <h3>{pkg.price}</h3>
                  <ul className="list-unstyled pricing-one__list">
                    {pkg.features.map((feature, i) => (
                      <li key={i} style={{ textAlign: 'left', position: 'relative', paddingLeft: '30px', lineHeight: '1.6' }}>
                        <i className="bi bi-check" style={{ fontSize: '1.2em', position: 'absolute', left: '0', top: '50%', transform: 'translateY(-50%)' }}></i> {feature}
                      </li>
                    ))}
                  </ul>
                  <a href="#none" className="thm-btn pricing-one__btn" onClick={() => handleSelectPackage(pkg)}>
                    <span>Chọn gói</span>
                  </a>
                </div>
              </div>
            </Col>
          ))}
        </Row>
      </div>

      {/* Modal thông tin thanh toán */}
      <Modal 
        show={showModal} 
        onHide={() => setShowModal(false)} 
        centered 
        dialogClassName="custom-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title>Thông tin chuyển khoản</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p style={{ marginBottom: '5px' }}><strong>Gói đã chọn:</strong> {selectedPackage.name}</p>
          <p style={{ marginBottom: '5px' }}><strong>Giá:</strong> {selectedPackage.price}</p>
          <hr style={{ width: '50%', margin: '20px auto', border: '1px solid #ccc' }} />
          <p style={{ marginBottom: '5px' }}><span style={{ fontWeight: "bold" }}>Ngân hàng Quân Đội MB Bank</span></p>
          <p style={{ marginBottom: '5px' }}>Số TK: <span style={{ fontWeight: "bold" }}>123889688</span></p>
          <p style={{ marginBottom: '5px' }}>Tên TK: <span style={{ fontWeight: "bold" }}>HOÀNG ĐIỆP</span></p>
          <p style={{ marginBottom: '5px' }}>Nội dung ghi: <span style={{ fontWeight: "bold" }}>SDT + CK IUDI</span></p>
          <p style={{ marginBottom: '5px' }}>Sau đó chụp hình hóa đơn gửi qua Zalo BQT: 0866.428.869</p>
          <div style={{ textAlign: 'center', margin: '15px 0' }}>
            <img src={qrCodeImage} alt="QR Code" style={{ width: "230px", height: "230px" }} />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>Đóng</Button>
        </Modal.Footer>
      </Modal>
    </section>
  );
};

export default Pricing;








